.ChatBot__Message{
	.content {
	    display: flex;
	    flex-direction: column;
	    flex: 1;
	    order: 1;
        width: 100%;
	}
}

.MessageTransition-appear {
    opacity: 0;
    transform: translate(0, -10px);
}

.MessageTransition-appear.MessageTransition-appear-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity .2s ease-in, transform .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.BubbleTransition-appear{
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top center;
}

.BubbleTransition-appear-active{
    opacity: 1;
    transform: scaleY(1);
    transition: opacity .2s ease-in, transform .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.MessageTimeUnderTransition-appear{
    opacity: 0;
    transform: translate(0, -10px);
}

.MessageTimeUnderTransition-appear.MessageTimeUnderTransition-appear-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity .3s ease-in .2s, transform .3s ease-in .2s;
}

.BottomHintsTransition-enter{
    opacity: 0;
    transform: translate(0, 20px);
}

.BottomHintsTransition-enter.BottomHintsTransition-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity .2s ease-in, transform .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    
}
.BottomHintsTransition-exit{
    opacity: 1;
    transform: translate(0, 0px);
}

.BottomHintsTransition-exit.BottomHintsTransition-exit-active {
    opacity: 0;
    transform: translate(0, 20px);
    transition: opacity .2s ease-in, transform .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    
}