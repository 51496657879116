.ChatBot__OuterWrapper{
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;

  &.with_sidebar .ChatBot__Sidebar{
  		width: 80px;
  }

}

.ChatBot__Loader{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #FFF;
	background-image: url("https://assets.reecall.co/gifs/spinner.gif");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 50px 50px;
	z-index: 3;
}

.ChatBot__Container{
	display: none;
    box-sizing: content-box;
    overflow: hidden;
    // border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px;
    width: 100%;
    height: 100%;

	&.open{
	    display: block;
	}

	.ChatBot__wrapper{
	    position: relative;
	    display: flex;
	    flex-direction: column;
	    align-items: flex-start;
	    justify-content: flex-start;
	    height: 100%;

	    &.ChatBot__wrapperWithFooter{
	    	height: calc(100% - 32px);
		    
		    .ChatBot__Footer{
				padding : 10px 0 12px;
		    }
	    }

	}
	
	.ChatBot__Main{
		position: relative;
		display: flex;
	    height: calc(100% - 66px);
	    width: 100%;

	    .container{
	    	height: 100%;
	    }

		.container > .ChatBot__Scroll {
			position: relative;
		    padding: 16px 0;
		    list-style: none;
		    margin-bottom: 0;
		    height: 100%;

		    overflow-y: auto;
		    overflow-x: hidden;
	  		scroll-behavior: smooth;

			//Remove scrollbar
		    scrollbar-width: none; /* Firefox */
		    -ms-overflow-style: none;  /* IE 10+ */
			&::-webkit-scrollbar { /* WebKit */
			    width: 0;
			    height: 0;
			}

		    // &.ChatBot__Scroll_withBottomHints{
		    // 	height: calc(100% - 120px);
		    // }

		}
	}
}

.ChatBot__OuterWrapper_rounded{

	.ChatBot__Header{
		padding: 0 0 25px;
	}

	.ChatBot__Main{
		border-radius: 25px 25px 0 0;
		margin-top: -25px;
		box-shadow: 0 -6px 20px -6px rgba(32,45,113,.08);
	}
}

.ChatBot__Sidebar{
	background-color: #6b79ff;

	width: 0;
	overflow: hidden;
	transition: width .4s ease;
}