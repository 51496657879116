@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
  }


@mixin shadow ($level: 1, $background: rgb(0,0,0)) {

    @if $level == 1 {
        box-shadow: 0 1px 3px rgba($background, 0.12), 0 1px 2px rgba($background, 0.24);
    } @else if $level == 2 {
        box-shadow: 0 3px 6px rgba($background, 0.16), 0 3px 6px rgba($background, 0.23);
    } @else if $level == 3 {
        box-shadow: 0 10px 20px rgba($background, 0.19), 0 6px 6px rgba($background, 0.23);
    } @else if $level == 4 {
        box-shadow: 0 14px 28px rgba($background, 0.25), 0 10px 10px rgba($background, 0.22);
    } @else if $level == 5 {
        box-shadow: 0 19px 38px rgba($background, 0.30), 0 15px 12px rgba($background, 0.22);
    }

}

@mixin ball {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: rgba(191, 202, 216, .5);
	z-index: 2;
	margin-top: 4px;
	animation: ball .45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
}

@keyframes ball { 
  from {
    transform: translateY(0) scaleY(.8);
  }
  to {
    transform: translateY(-10px);
  }
}