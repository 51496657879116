.ChatBot__Footer{
	width: 100%;
    padding: 10px 0 30px;
    // border-top: 2px solid #f9f9f9;

	form{
	    position: relative;
	    margin-bottom: 0;
	}

	textarea, input[type=text]{
	    padding: 15.5px 50px 15.5px 20px;
	    height: 56px;
	    resize: none;
	}

	.btn{
	    position: absolute;
	    padding: 0 0 0 15px;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    line-height: 0;
	    margin-bottom: 0!important;

	    padding: 0 15px;
	    left: auto;
	    right: 0;

	    &.round{
	    	top: 6px;
	    	right: 6px;
	    	bottom: 6px;
	    	border-radius: 50%;
	    	padding: 0 9px;
	    }
	}


	.form-control {
		border: none;
		font-size: 16px;
		font-weight: 500;

		&:focus {
			border: none !important;
		}

		&::placeholder {
			font-size: 16px;
			font-weight: 500;
		}
	}
}