.dl-button {
    display: block;
    text-decoration: none;
    color: var(--text-color);
    box-shadow: var(--shadow);

    & > div {
        position: relative;
        background: var(--bg-color);
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        padding: 16px 24px;

        .icon {
            --color: var(--text-color);
            margin-right: 12px;
            position: relative;
            transform: translateZ(8px);
            div {
                overflow: hidden;
                position: relative;
                width: 20px;
                height: 22px;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    width: 2px;
                    height: 2px;
                    top: 2px;
                    transition: opacity .3s ease;
                }
                &:before {
                    left: 6px;
                    background-image: radial-gradient(circle at 0 100%, var(--color) 2px, #fff 0px)
                }
                &:after {
                    right: 6px;
                    background-image: radial-gradient(circle at 100% 100%, var(--color) 2px, #fff 0px)
                }
                svg {
                    width: 20px;
                    height: 18px;
                    display: block;
                    margin-top: 2px;
                    position: relative;
                    z-index: 1;
                    &.arrow {
                        color: var(--arrow-color);
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 2;
                        transform: translateY(-1px);
                    }
                    &.shape {
                        color: var(--color);
                        transition: color .4s ease;
                    }
                }
            }
        }
        .label {
            --color: var(--text-color);
            line-height: 22px;
            font-size: 16px;
            font-weight: 500;
            color: var(--color);
            position: relative;
            transition: color .4s ease;
            transform: translateZ(8px);
            & > div {
                display: flex;
                transition: opacity .25s ease;
                &:not(.show) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                }
                &.hide {
                    opacity: 0;
                }
            }
        }
        .progress {
            --s: 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 3px;
            transform-origin: 50% 100%;
            transform: scaleY(var(--s));
            transition: transform .4s ease;
            &:before,
            &:after {
                content: '';
                background: var(--success);
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                transform-origin: 0 50%;
                transform: scaleX(var(--progressScale));
            }
            &:before {
                opacity: .35;
            }
            &:after {
                transition: transform calc(var(--duration) * .9ms) ease-in-out;
            }
        }
    }
    &.active {
        & > div {
            .icon {
                div {
                    &:before,
                    &:after {
                        opacity: 0;
                        transition-delay: .4s;
                    }
                }
                svg {
                    &.arrow {
                        animation: arrow calc(var(--duration) * .18ms) linear 4 calc(var(--duration) * .2ms);
                    }
                }
            }
            .progress {
                --s: 1;
                transition-delay: .4s;
                &:after {
                    transition-delay: .4s;
                }
            }
        }
    }
    &.done {
        & > div {
            .icon {
                --color: var(--success);
            }
        }
        .label {
            --color: var(--success);
        }
    }
}

@keyframes arrow {
    38% {
        transform: translateY(100%);
        opacity: 1;
    }
    39% {
        transform: translateY(100%);
        opacity: 0;
    }
    40% {
        transform: translateY(-100%);
        opacity: 0;
    }
    41% {
        transform: translateY(-100%);
        opacity: 1;
    }
    100% {
        transform: translateY(-1px);
        opacity: 1;
    }
}

@keyframes motion {
    20%,
    70% {
        filter: blur(.4px);
    }
}