
/*
* Datepicker
*/
.DateRangePicker{

    width: 100%;
   	min-height: 300px;

	.DateInput{
		max-width: 130px;
		width: 100%;
	}

	.DateInput_fang{
		display: none;
	}

	.DayPickerNavigation_button__default{
		border: none;
	}

	.DayPicker_weekHeader{
		top: 56px;
	}

	.DayPicker_weekHeader_li {
	  visibility: hidden;
	  font-size: 0;
	}

	.DayPicker_weekHeader_li::first-letter {
	  visibility: visible;
	  font-size: 18px;
	}

	.CalendarDay__default{
		position: relative;
		border: none;
    	vertical-align: middle;
    	z-index: 1;
		color: $reecall-black;

		&:hover{
			background-color: $reecall-blue4;
		}
	}

	.CalendarDay__selected_span {
		background-color: $reecall-blue4;
	}

	.CalendarDay__selected {
		position: relative;
		background-color: $reecall-blue4;
		color: #fff;
		z-index: 0;

		&:before{
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $reecall-blue1;
			border-radius: 6px;
			z-index: -1;
		}
	}

	.CalendarDay__selected_start{
		border-radius: 6px 0 0 6px;
	}

	.CalendarDay__selected_end{
		border-radius: 0 6px 6px 0;
	}

	.CalendarDay__hovered_span:hover,
	.CalendarDay__hovered_span {
		background-color: $reecall-blue4;
		color: $reecall-black;
	}

	.CalendarMonth_caption{
		font-size: 16px;
		line-height: 22px;
		font-weight: 600;
		padding-bottom: 57px;
	}

	.DayPicker_weekHeader_li{
		text-transform: uppercase;
		color: $reecall-black2;
	}

	.DayPickerNavigation_button__horizontal{
	    display: flex;
	    flex-direction: row;
		position: absolute;
		top: 18px;
    	padding: 5px;

		&:first-child{
			left: 25px;
		}

		&:last-child{
			right: 25px;
		}
	}

	.DayPickerNavigation_button__horizontalCustom{
	    position: relative;
		width: 20px;
		height: 20px;
		color: $reecall-black2;
	
		&.DayPickerNavigation_left{
		    margin-right: auto;
		    margin-left: 0px;
		} 

		&.DayPickerNavigation_right{
		    margin-left: auto;
		    margin-right: 0px;
		}
	}
}