@import 'Reset';
@import 'mixins';
@import 'Colors';
@import 'Chat';
@import 'ChatHeader';
@import 'ChatFooter';
@import 'ChatMessage';
@import 'FloatButtons';
@import 'DatePicker';
@import 'Download';

html, body{
  width: 100%;
  height:100%;
  background-color: transparent;
}

#root{
  height: 100%;
  width: 100%;
}

body {
  position: relative;
  font-family: 'Inter UI', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.7;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background-color: transparent;
}

a {
  transition: all 0.3s;
  &:hover, &:visited, &:link, &:active {
    color: inherit;
    text-decoration: none !important;

  }
}

p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.RC_footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 0;

    text-align: right;

    font-size: 12px;
    line-height: 10px;

    z-index: 125;
    color: #bfcad8;

    .container{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    // border-top: 2px solid #f9f9f9;

    .RC_footer__logo{
      margin-left: 4px;
      height: 16px;
    }
}