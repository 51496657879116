/* REECALL COLORS */

$reecall-blue: #2e43e7;
$reecall-blue1: #475cff;
$reecall-blue2: #6b79ff;
$reecall-blue3: #daddff;
$reecall-blue4: #eceeff;
$reecall-blue5: #f6f7ff;

$reecall-red: #ff5451;
$reecall-red2: #fea090;
$reecall-red1: #ffecec;

$reecall-green: #11cb72;
$reecall-green1: #22e186;
$reecall-green2: #44e89c;
$reecall-green3: #a4f4ce;
$reecall-green4: #e7fcf2;

$reecall-yellow: #ffd98c;
$reecall-yellowl: #fffbf3;
$reecall-yellow2: #fef5e2;

$reecall-violet: #b9b4ff;
$reecall-purple: #d6a5ff;

$reecall-black: #444e5a;
$reecall-black1: #8292a5;
$reecall-black2: #bfcad8;
$reecall-black3: #e5eaef;
$reecall-black4: #f3f3f3;
$reecall-black5: #f9f9fa;

$reecall-background: #f7f7f9;
