.ChatBot__Header{
    padding: 0;   
    width: 100%;
	
	.container{
		display: flex;
	    align-items: center;
	    justify-content: space-between;
	}

	img {
	    width: 100px;
	    margin-right: 15px;
	}
	
	h5 {
	    font-size: 16px;
	    font-weight: 600;
	    margin-bottom: 8px;
	}

	span {
	    display: block;
	    font-size: 15px;
	    font-weight: 500;
	    line-height: 1;
	}
}