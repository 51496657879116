.ChatBot__FloatButtons{
	display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

	&.opened{
		display: none;
	}
}

.ChatBot__FloatButton{
	display: flex;
	justify-content: center;
	background: rgb(70, 97, 246);
	height: 60px;
	width: 60px;
	border: 0px;
	border-radius: 50%;
	// box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px;
	cursor: pointer;
}

.ChatBot__TextHover{
	margin-right: 12px;
}